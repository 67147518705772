import { Component, Injector, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  AbstractComponent,
  Campaign,
  CampaignExecutionStatus,
  CampaignService,
  Channel,
  ChannelService,
  ConfirmationComponent,
  Pager,
} from 'lib-trend-core'
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs'
import { CampaigSendMessageComponent } from '../campaign-send-message/campaign-send-message.component'

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrl: './campaign-list.component.scss',
})
export class CampaignListComponent extends AbstractComponent implements OnInit {
  campaignStatuses = [
    { status: null, label: 'Todos' },
    { status: CampaignExecutionStatus.WAITING, label: 'Aguardando' },
    { status: CampaignExecutionStatus.DONE, label: 'Concluída' },
    { status: CampaignExecutionStatus.SENDING, label: 'Enviando' },
    { status: CampaignExecutionStatus.ERROR, label: 'Erro' },
  ]

  isSortedByCost: boolean = false;
  filterStatus: string = '';
  labelFilter: string = 'Campanha';
  labelFilterChannel: string = 'Canal';
  channels: Array<Channel> = [];

  pager: Pager<Campaign> = new Pager<Campaign>({ perPage: 10 })
  listObservable: Observable<Pager<Campaign>>
  defaultValue: string = ''

  totalCampaign: string = ''
  totalMessage: string = ''
  totalCoust: string = ''

  private termOfSearch: Subject<string> = new Subject<string>()
  private selectedChannelId: string | null = null
  private selectedChannelIds: string[] = []

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    public campaignService: CampaignService,
    public channelService: ChannelService
  ) {
    super(injector)
    this.loadingContent = true
  }

  /***/
  ngOnInit() {
    this.getList()
    this.getDataAnalytics()
    this.loadAllChannels()
  }

  /***/
  getList(channelId?: string) {
    this.loadingContent = true;
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    }
    if (channelId) {
      this.searchParams['channel'] = channelId
    }

    if (this.filterStatus) {
      this.searchParams['status'] = this.filterStatus
    }

    this.campaignService
      .getAll(
        this.pager.page,
        this.pager.perPage,
        this.searchString,
        this.searchParams
      )
      .subscribe({
        next: (pager: Pager<Campaign>) => {
          this.pager = pager
          this.pager.list.sort((a, b) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          });
          this.setupObservableSearch()
        },
        complete: () => (this.loadingContent = false),
        error: (err) => this.alertService.error(err.error.message),
      })
  }

  /***/
  loadPage(page: number) {
    this.pager.page = page
    this.getList()
  }

  edit(campaign: Campaign) {
    this.router.navigate([`/campaign/${campaign._id}/edit`], {
      relativeTo: this.route,
    })
  }

  /***/
  delete(campaign: Campaign) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result) === true) {
        this.campaignService.delete(campaign._id).subscribe({
          next: (value) => {
            this.getList()
            this.alertService.success()
          },
          error: (err) => this.alertService.error(err.error.message),
        })
      }
    })
  }

  /***/
  showNewCampaign() {
    this.router.navigate(['/campaign/new'], { relativeTo: this.route })
  }

  /***/
  setupObservableSearch() {
    this.listObservable = this.termOfSearch
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(
        switchMap((term) => {
          return this.campaignService.getAll(
            this.pager.page,
            this.pager.perPage,
            term,
            this.searchParams
          )
        })
      )
    this.listObservable.subscribe((pager: Pager<Campaign>) => {
      this.pager = pager
    })
  }

  /***/
  search(term: string) {
    this.pager.page = 1
    this.searchString = term
    this.termOfSearch.next(term)
  }

  formatTags(campaign: Campaign) {
    return campaign.audienceTags?.map((t) => t.title).join(', ')
  }

  formatContactGroups(campaign: Campaign) {
    return campaign.audienceContactGroup?.map(t => t.name).join(', ');
  }

  private getDataAnalytics(): void {
    this.campaignService.getDataAnalytics().subscribe({
      next: (value: Array<{ coust: number, totalMessages: number, totalRecord: number }>) => {
        this.totalCampaign = value
          .reduce((acc, analytics) => acc + (analytics.totalRecord || 0), 0).toString();
        this.totalMessage = value
          .reduce((acc, analytics) => acc + (analytics.totalMessages || 0), 0).toString();
        this.totalCoust = value
          .reduce((acc, analytics) => acc + (analytics.coust || 0), 0).toFixed(2).toString();
      },
      error: () =>
        this.alertService.error(
          'Ops! Não foi possível trazer os dados das campanhas. Tente novamente mais tarde.'
        ),
    })
  }

  setValueSearch(term: string, label: string = 'Campanhas'): void {
    this.defaultValue = term
    this.formGroup.get('campaign')?.setValue(term)
    this.labelFilter = label
  }

  listCampaignContact(item: Campaign) {
    const dialog = this.dialog.open(CampaigSendMessageComponent, {
      width: '800px',
      data: {
        campaignId: item._id,
        audienceTags: item.audienceTags,
      },
    })
  }

  private loadAllChannels() {
    this.channelService.getList().subscribe((channels) => {
      if (channels) this.channels = channels
    })
  }

  setStatusFilter(status: CampaignExecutionStatus | null) {
    if (status === null) {
      this.filterStatus = ''
      this.labelFilter = 'Todos'
    } else {
      this.filterStatus = status
      this.labelFilter =
        this.campaignStatuses.find((s) => s.status === status)?.label ||
        'Status desconhecido'
    }
    this.getList(this.selectedChannelId)
  }

  toggleSortByCost() {
    this.isSortedByCost = !this.isSortedByCost
    this.getList(this.selectedChannelId)
  }

  setFilterChannel(channelId: string) {
    this.selectedChannelId = channelId
    this.getList(channelId)
    const channel = this.channels.find((c) => c._id === channelId)
    if (channel) {
      this.labelFilterChannel = channel.name
    } else {
      this.labelFilterChannel = 'Canal'
    }
  }

  clearChannelFilter() {
    this.selectedChannelIds = []
    this.labelFilterChannel = 'Canal'
    this.getList()
  }

  
}
