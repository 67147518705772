import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractComponent, CampaignService, FirebaseService, UploadTypeEnum, UtilHelper } from 'lib-trend-core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';

@Component({
  selector: 'campaign-evo-template',
  templateUrl: './campaign-evo-template.component.html',
  styleUrls: ['./campaign-evo-template.component.scss']
})
export class CampaignEvoTemplateComponent extends AbstractComponent implements OnInit, OnDestroy {

  @Output('paramsTemplate') templateParams: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Input('inputText') inputText: string;


  file: File;
  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinnerTemplate$ = this.loadingSpinnerSubject.asObservable();
  uploadHeader: boolean = false;
  midiaHeader: any;
  readOnlyInputVariable = true;
  currentTime: string = '';
  private timeSubscription: Subscription;


  constructor(
    injector: Injector,
    public firebaseService: FirebaseService,
    public campaignService: CampaignService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      inputText: [this.inputText || ''],
      image: [null],
      nonTemplateType: [null],
      nonTemplateContentType: [null]
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.updateDataTemplate();
    });

    this.timeSubscription = interval(1000).subscribe(() => {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString();
    });
  }
  ngOnChanges() {
    if (this.formGroup && this.inputText) {
      this.formGroup.patchValue({
        inputText: this.inputText
      });
    }
  }

  updateDataTemplate() {
    const templateData = {
      nonTemplateMidia: this.midiaHeader,
      nonTemplateType: this.formGroup.value.nonTemplateType,
      nonTemplateContentType: this.formGroup.value.nonTemplateContentType,
      nonTemplateMessage: this.formGroup.value.inputText
    };
    this.templateParams.emit(templateData);
  }

  back() {
    history.back();
  }

  async save() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const campaign = { ...this.formGroup.value };
    await this.campaignService.create(campaign).subscribe({
      next: (value) => this.alertService.success(),
      error: (err) => this.alertService.error(err.error.message),
      complete: () => this.back(),
    });
  }

  onSelectImagem(event: Event) {
    const target = event.target as HTMLInputElement;
    const files: FileList | null = target.files;

    if (files && files.length > 0) {
      this.file = files[0];

      const mimeType = this.file.type;
      let fileType = '';

      if (mimeType.startsWith('image/')) {
        fileType = 'image';
      } else if (mimeType.startsWith('video/')) {
        fileType = 'video';
      } else if (mimeType.startsWith('audio/')) {
        fileType = 'audio';
      } else {
        fileType = 'text';
      }

      let reader = new FileReader();
      reader.onload = async (file) => {
        if (this.file) {
          this.loadingSpinnerSubject.next(true);
          this.uploadHeader = true;
          const contents = file.target;
          const base64 = UtilHelper.arrayBufferToBase64(contents.result as ArrayBuffer);
          const dateMilisecond = new Date().getTime();
          const filename: string = dateMilisecond.toString();

          this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.MESSAGE).then((snapshot: { url: string }) => {
            this.loadingSpinnerSubject.next(false);
            this.midiaHeader = snapshot.url;

            this.formGroup.patchValue({
              image: snapshot.url,
              nonTemplateType: fileType,
              nonTemplateContentType: mimeType
            });
          });

        }
      };
      reader.readAsArrayBuffer(this.file);
    }
  }

  updateInputVariable(variableName: string) {
    const inputField = this.formGroup.get('inputText');
    if (inputField) {
      const currentValue = inputField.value || '';
      const newValue = `${currentValue} ${variableName}`;
      inputField.setValue(newValue);
    }
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }
}
