<div class="ml-2 h-[400px]">
  <!-- <div class=" w-full bg-[#005C4B] text-white flex items-center p-1 px-4 rounded-t-lg z-1 relative">
    <div class="flex items-center space-x-3 mr-auto  text-sm">
      <span>{{ currentTime }}</span>
    </div>

    <div class=" inset-0 flex items-center justify-center">
      <div class="w-3 h-3 bg-black rounded-full flex items-center justify-center">
        <div class="w-1 h-1 bg-[#5c5b5b] rounded-full"></div>
      </div>
    </div>

    <div class="flex items-center space-x-3 ml-auto">
      <mat-icon class="text-white">signal_cellular_4_bar</mat-icon>
      <mat-icon class="text-white">battery_full</mat-icon>
    </div>
  </div> -->
<div class="h-[586px] w-full bg-[#fff] mt-[-5px] rounded-lg img-bg">
    <div class="scroll-img" >
    <div class="container-msg shadow-global">
      <p *ngIf="!selectedTemplate && selectedChannel?.type === 'CLOUD_API'">Nenhum template selecionado. Escolha um ao
        lado para começar.</p>
        <div class=" upload-preview" [hidden]="selectedTemplate?.headerComponent?.format !== 'IMAGE'">
          <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-42 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white">
            <div class="relative flex flex-col items-center justify-center w-full h-full">
              <loading-component class="loading-template" *ngIf="loadingSpinnerTemplate$ | async"></loading-component>
          
              <div *ngIf="!midiaHeader">
                <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#E6E6FF" />
                  <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#F1F1FF" stroke-width="8" />
                  <g clip-path="url(#clip0_6074_436)">
                    <path d="M32.5 32L28.5 28M28.5 28L24.5 32M28.5 28V37M36.89 34.39C37.8653 33.8583 38.6358 33.0169 39.0798 31.9986C39.5239 30.9804 39.6162 29.8432 39.3422 28.7667C39.0682 27.6901 38.4434 26.7355 37.5666 26.0534C36.6898 25.3714 35.6108 25.0007 34.5 25H33.24C32.9373 23.8292 32.3731 22.7423 31.5899 21.821C30.8067 20.8996 29.8248 20.1678 28.7181 19.6806C27.6113 19.1933 26.4085 18.9633 25.2001 19.0079C23.9916 19.0524 22.809 19.3703 21.7411 19.9376C20.6732 20.505 19.7479 21.3071 19.0346 22.2836C18.3213 23.26 17.8387 24.3855 17.6229 25.5754C17.4072 26.7652 17.4641 27.9885 17.7892 29.1532C18.1143 30.318 18.6992 31.3938 19.5 32.3" stroke="#4213F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_6074_436">
                      <rect width="24" height="24" fill="white" transform="translate(16.5 16)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
          
              <div *ngIf="!midiaHeader" class="text-center">
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold text-[#4213F6] text-xl">Clique aqui para carregar</span> ou arraste e solte
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG ou JPEG (MAX. 800x400px)</p>
              </div>
          
              <img class="rounded-lg" *ngIf="midiaHeader" [src]="midiaHeader" alt="image" style="width: 100%; height: 100%; object-fit: cover;">
            </div>
          
            <input id="dropzone-file" type="file" class="hidden" (change)="onSelectImagem($event)" />
          </label>
        </div>
        
        
      <div class="text-sm" [hidden]="selectedTemplate?.headerComponent?.format !== 'TEXT'">
        {{selectedTemplate?.headerComponent?.text}}
      </div>

      <div class="wa-text text-sm">
        <p>{{previewText}}</p>
      </div>

      @if (selectedTemplate?.footerComponent) {
      <div class="wa-footer text-sm">
        <p>{{selectedTemplate?.footerComponent?.text}}</p>
      </div>
      }
      @if (selectedTemplate?.buttonsComponent) {
        <div class="wa-buttons text-sm flex flex-col items-center w-full">
          @for (button of selectedTemplate?.buttonsComponent?.buttons; track $index) {
            <div class="flex items-center justify-center w-full border-t border-gray-500 py-1">
              <mat-icon style="font-size: 16px; color: white;">reply</mat-icon> 
              <span class="ml-2">{{button.text}}</span>
            </div>
          }
        </div>
      }
    </div>
    <form [formGroup]="form" class="w-full">
      <ng-container *ngFor="let variable of templateVariableList; let i = index;">
        <mat-menu #menuVar="matMenu">
          <button mat-menu-item (click)="selectVarContactLead(i + 1)">
            <mat-icon color="primary">person</mat-icon> Nome do contato
          </button>
          <button mat-menu-item (click)="selectVarGreeting(i + 1)">
            <mat-icon color="primary">pan_tool</mat-icon> Saudação
          </button>

          <button mat-menu-item (click)="selectVarOperatorName(i + 1)">
            <mat-icon color="primary">perm_contact_calendar</mat-icon> Nome atendente
          </button>
          <button mat-menu-item (click)="selectVarDepartmentName(i + 1)">
            <mat-icon color="primary">business</mat-icon> Departamento
          </button>
        </mat-menu>
        <div class="container-var">
          <div class="flex justify-center items-center w-full ">
            <input class="input-field" type="text" [formControlName]="'var' + (i + 1)" placeholder="Nome">
            <button [matMenuTriggerFor]="menuVar" class="var-button">
              Variáveis
            </button>
 
          </div>
        </div>
      </ng-container>
      <div
      *ngIf="!templateVariableList || templateVariableList.length === 0 && selectedChannel?.type === 'CLOUD_API' && selectedTemplate"
      class="flex justify-center items-center mb-2 p-2 relative z-10 w-full bg-white rounded-lg ">
      <mat-icon class="text-red-600">error</mat-icon>
      <p class=" text-gray-500 dark:text-gray-400">Não há variáveis nesse template.</p>
    </div>
    </form>
  </div>
</div>


</div>