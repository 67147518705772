import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CampaignTemplateListComponent } from './campaign-template-list-dialog/campaign-template-list.component';

const routes: Routes = [
  { path: '', component: CampaignListComponent, },
  { path: 'new', component: CampaignFormComponent, },
  { path: ':id/edit', component: CampaignFormComponent, },
  { path: 'list-template', component: CampaignTemplateListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignRoutingModule { }
