  <div class="w-full flex justify-center flex-col">
    <div class="flex w-100 flex-1 justify-start mb-4">
      <div
        class="flex justify-center items-center w-[50px] h-[50px] rounded-full bg-green-100 border-green-50 border-8">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#039855" />
        </svg>
      </div>
    </div>
    <div>
      <h1 class="font-black text-[20px] text-black">Templates de atendimento</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">Selecione um template</h6>
    </div>
  </div>

  <wa-template-select [channel]="selectedChannel" (onSelectTemplate)="onSelectTemplate($event)" />




