<div>
  <div class="w-full flex justify-between items-center">
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
      <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
    </svg>
    <button (click)="closeDialog()"
      class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300">
      <span class="material-icons">close</span>
    </button>
  </div>

  <td class="whitespace-nowrap px-3 p-2 text-sm text-gray-500">

  </td>
  <div class="flex justify-between">
    <div class="flex flex-col">
      <h1 class="font-black text-[20px] text-black ">
        Resultado da Campanha
      </h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">Listas dos contatos que foram alcançados com a campanha</h6>
      <h1 class="text-black font-light text-sm my-2"> Público-alvo :
        <ng-container *ngFor="let tag of formatTags(); let last = last">
          <span class="shadow-md px-[5px] py-[2px] font-semibold rounded-lg text-[15px]"
            [ngStyle]="{'background-color': tag.bgColor, 'color': tag.fontColor}">
            {{ tag.title }}
          </span>
          <span class="p-1" *ngIf="!last"></span>
        </ng-container>
      </h1>
    </div>
    <card-graphic-small-component [label]="dynamicStatusLabel" [total]="filteredCount">
    </card-graphic-small-component>
  </div>
</div>
<div *ngIf="loading">
  <loading-component class="loading"></loading-component>
</div>

<div *ngIf="!loading" class="pt-4">
  <mat-select class="input-field max-w-[100%] mb-[15px]" [formControl]="statusControl">
    <mat-select-trigger>
      {{ statusTranslations[statusControl.value] || 'Selecione o status' }}
    </mat-select-trigger>
    <mat-option *ngFor="let status of statusList" [value]="status">
      {{ statusTranslations[status] }}
    </mat-option>
  </mat-select>

  <div class="overflow-hidden rounded-lg border border-gray-200 shadow-global" *ngIf="pager.total !== 0">
    <table *ngIf="pager.total > 0" class="min-w-full bg-white">
      <thead class="bg-gray-100">
        <tr>
          <th class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Data de Envio</th>
          <th class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Status</th>
          <th class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
          <th class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Telefone</th>
          <!-- <th class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Grupos</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of pager.list" class="hover:bg-gray-50">
          <td class="py-2 px-4 border-b text-gray-700">{{ item.createdAt | date: 'dd/MM/yyyy HH:mm:ss ' }}</td>
          <td class="py-2 px-4 border-b text-gray-700">
            {{ item.status === 'ERROR' ? 'Erro' : item.status === 'SUCCESS' ? 'Sucesso' : item.status ? item.status :
            '-' }}
          </td>
          <td class="py-2 px-4 border-b font-semibold">
            <div class="flex gap-3 flex-1">
              <img class="w-8 h-8 rounded-full" [src]="getContactImage(item.contact)" alt="{{item.contact?.name}}" (error)="onImageError($event)">
              <span class="text-x text-ellipsis overflow-hidden">{{item.contact?.name}}
              </span>
            </div>
          </td>
          <td class="py-2 px-4 border-b text-gray-700">{{ item.contact?.phone }}</td>
          <!-- <td class="py-2 px-4 border-b text-gray-700">{{ item.contact?.groups }}</td> -->
        </tr>
      </tbody>
    </table>
  </div>


  <empty-records-component *ngIf="pager.total === 0" [message]="'Nenhum resultado foi encontrado.'"
    [subMessage]="'Tente ajustar o filtro ou redefinir para ver todos os resultados.'">
  </empty-records-component>

</div>
<paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
  (onPager)="loadPage($event)">
</paginator>