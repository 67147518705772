import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService, Campaign, CampaignAudienceType, ChannelService, ChannelTypeEnum, ContactGroup, ContactService, Tag, WaTemplateResponse } from 'lib-trend-core';

export interface CampaingSaveConfirmData {
  leadCount: number;
  cost: number;
}

@Component({
  selector: 'app-campaing-save-confirm-modal',
  templateUrl: './campaing-save-confirm-modal.component.html',
  styleUrl: './campaing-save-confirm-modal.component.scss'
})
export class CampaingSaveConfirmModalComponent implements OnInit {

  COST_BY_LEAD: number = 0;
  private costMapping: { [key: string]: number } = {
    MARKETING: 0.0625,
    UTILITY: 0.0080,
    AUTHENTICATION: 0.0315,
    SERVICE: 0.0300
  };

  confirmedData: CampaingSaveConfirmData;
  campaign: Campaign;
  tags: Tag[];
  contactGroups: ContactGroup[];
  audience: string;
  leadsCount = 0;
  cost = 0.00

  selectedTemplate: Partial<WaTemplateResponse>
  evolutionAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_API;

  constructor(
    public dialogRef: MatDialogRef<CampaingSaveConfirmModalComponent>,
    public alertService: AlertService,
    public contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.campaign = data.campaign;
    this.tags = data.tags;
    this.contactGroups = data.contactGroups;
    this.selectedTemplate = data.selectedTemplate;
  }

  async ngOnInit(): Promise<any> {
    if (!this.campaign) return;

    const category = this.data.channel.type === ChannelTypeEnum.CLOUD_API ? this.selectedTemplate.category : null;
    this.COST_BY_LEAD = this.costMapping[category] || 0;

    if (CampaignAudienceType.ALL_LEADS === this.campaign.audienceType) {
      this.leadsCount = await this.contactService.count([]);
      this.audience = 'Todos os Leads';
    }
    else if (CampaignAudienceType.TAGS === this.campaign.audienceType) {
      let idsTags = this.campaign.audienceTags as any[];
      this.leadsCount = await this.contactService.count(idsTags);
      this.audience = this.tags.map((t: Tag) => t.title).join(', ');
    } else {
      let idsContactGroups = this.campaign.audienceContactGroup as any[];
      this.leadsCount = await this.contactService.countContactGroup(idsContactGroups);
      this.audience = this.contactGroups.map((t: ContactGroup) => t.name).join(', ');
    }

    this.cost = this.leadsCount * this.COST_BY_LEAD;
  }

  cancel() {
    this.dialogRef.close(null);
  }

  confirmSaveCampaign() {

    if (this.leadsCount === 0) {
      this.alertService.error("Nenhum lead foi encontrado.");
      return;
    }

    this.confirmedData = {
      leadCount: this.leadsCount,
      cost: this.cost
    };
    this.dialogRef.close(this.confirmedData);
  }

}
